import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { character, client01, lightLogo, logoDark } from '../imageImport'

import { ethers } from 'ethers'
import Jazzicon from '@raugfer/jazzicon';
import web3 from 'web3'
import '../../common.css';
var jazzicon = require('jazzicon')


const axios = require('axios').default;
const Navbar = () => {
  const [myPublicAddress, setMyPublicAddress] = useState('N/A')
  const [balanceeth,setBalanceeth] = useState('N/A')
  const location = useLocation()
  const navigate = useNavigate()
  const url = useMemo(() => location?.pathname === '/blog-detail', [])
  const templatePage = [
    '/become-creator',
    '/creator-profile',
    '/item-detail-one',
    '/index-two',
    '/index-four',
    '/token-sports',
    '/index-five',
    '/index-five-rtl',
    '/index-four-rtl',
    '/index-two-rtl'
  ]


  const becomeUrl = templatePage.includes(location?.pathname)
  const [mobile, setMobile] = useState([])
  const [ele,setEle]=useState('http://tokensports.rbdemo.live/static/media/01.c1b1d0b4.jpg');
  const toggleSwitcher = () => {
    var i = document.getElementById('style-switcher')
    if (i) {
      if (i.style.left === '-189px') {
        i.style.left = '0px'
      } else {
        i.style.left = '-189px'
      }
    }
  }
  const mobileHandler = (e, panel) => {
    e.preventDefault()
    const dataIndex = mobile?.findIndex(data => data === panel)
    if (dataIndex === -1) {
      setMobile([...mobile, panel])
    } else {
      const updateRecord = mobile?.filter((data, index) => index !== dataIndex)
      setMobile(updateRecord)
    }
  }
  var user = localStorage.getItem('token');
  const closeModal = () => {
    //   metamask modal
    const modal = document.getElementById('modal-metamask')

    modal.classList.remove('show')
    modal.style.display = 'none'
  }

  const isMetaMaskInstalled = useCallback(() => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window
    return Boolean(ethereum && ethereum.isMetaMask)
  }, [])

  const checkWalletConnet = useCallback(async () => {
    if (isMetaMaskInstalled()) {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' })
      if (!!accounts[0]) {
        const walletAddress =
          accounts[0].split('').slice(0, 6).join('') +
          '...' +
          accounts[0]
            .split('')
            .slice(accounts[0].length - 7, accounts[0].length)
            .join('')
        setMyPublicAddress(walletAddress)
      }
    }
  }, [isMetaMaskInstalled])

  useEffect(() => {
    checkWalletConnet()
   
    
    //body.appendChild(el)
  }, [checkWalletConnet])



  
  function buildDataUrl(address: string): string {
    return 'data:image/svg+xml;base64,' + btoa(Jazzicon(address));
  }





  useEffect(() => {
    var body = document.querySelector('body')
   // for(var i = 0; i < 60; i++) {
    const imageUrl = buildDataUrl('0xC871D4F6cFAc76b2c8b3a4F9a821279DD80e4813');
    
      setEle(imageUrl);
     
     // body.appendChild(el)
  //  }
  }, [])




const access_token=localStorage.getItem('token');

  useEffect(() => {
  
    axios.get('https://nftbe.rbdemo.live/api/v1/auth/me', {
      headers: {
        'Authorization': `Bearer ${access_token}`
      }
    })
    .then((res) => {
      localStorage.setItem("me", JSON.stringify(res));
    })
    .catch((error) => {
      console.error(error)
    })

    async function fetchMyAPI() {
//connect wallet
const { ethereum } = window;

  if (!ethereum) {
    alert("Please install Metamask!");
  }

  try {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    console.log("Found an account! Address: ", accounts);
    setMyPublicAddress(accounts[0]);
   // const balance = await web3.eth.getBalance(myPublicAddress);
if(accounts[0] !=='N/A'){
  //web3.eth.getBalance(accounts[0]).then(console.log('bbbbb'+accounts[0]));
  const imageUrl = buildDataUrl(accounts[0]);
    
  setEle(imageUrl);

if (typeof window.ethereum !== 'undefined') {
  // Instance web3 with the provided information
  //web3 = new Web3(window.ethereum);

  // Request account access
  await window.ethereum.enable();

/**     */

const network = 'mumbai' // use rinkeby testnet
const provider = ethers.getDefaultProvider(network)
const address =accounts[0]
provider.getBalance(address).then((balance) => {
 // convert a currency unit from wei to ether
 const balanceInEth = ethers.utils.formatEther(balance)
 //console.log(`balance: ${balanceInEth} ETH`)
 setBalanceeth(balanceInEth);
})


/**     */

  return true




}
}


    //console.log(balance)
   // setBalanceeth(balance);


  } catch (err) {
    console.log(err)
  }
}

fetchMyAPI();
//end
  }, [])



//for(var i = 0; i < 60; i++) {

  //}



const userdisplay= localStorage.getItem('name');



  const _handleConnectWallet = useCallback(async () => {
    const modal = document.getElementById('modal-metamask')
  
  //  alert(myPublicAddress);
    if(myPublicAddress !='N/A'){
      alert('connected');
    } 
    else{
      alert('Already connected ...  ' );
    }
   
    

    if (!isMetaMaskInstalled()) {
      //meta mask not installed
      modal.classList.add('show')
      modal.style.display = 'block'
      return
    }
    try {
      await window.ethereum.request({ method: 'eth_requestAccounts' })
      const accounts = await window.ethereum.request({ method: 'eth_accounts' })
      const walletAddress =
        accounts[0].split('').slice(0, 6).join('') +
        '...' +
        accounts[0]
          .split('')
          .slice(accounts[0].length - 7, accounts[0].length)
          .join('')
      setMyPublicAddress(walletAddress)
    } catch (error) {
      console.error(error)
    }
  }, [isMetaMaskInstalled])


  const getClosest = (elem, selector) => {

    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function (s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) { }
          return i > -1;
        };
    }

    // Get the closest matching element
    for (; elem && elem !== document; elem = elem.parentNode) {
      if (elem.matches(selector)) return elem;
    }
    return null;

  };

  const activateMenu = () => {
    var menuItems = document.getElementsByClassName("sub-menu-item");
    if (menuItems) {

      var matchingMenuItem = null;
      for (var idx = 0; idx < menuItems.length; idx++) {
        if (menuItems[idx].href === window.location.href) {
          matchingMenuItem = menuItems[idx];
        }
      }

      if (matchingMenuItem) {
        matchingMenuItem.classList.add('active');
        var immediateParent = getClosest(matchingMenuItem, 'li');
        if (immediateParent) {
          immediateParent.classList.add('active');
        }

        var parent = getClosest(matchingMenuItem, '.parent-menu-item');
        if (parent) {
          parent.classList.add('active');
          var parentMenuitem = parent.querySelector('.menu-item');
          if (parentMenuitem) {
            parentMenuitem.classList.add('active');
          }
          var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        } else {
          var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
          if (parentOfParent) {
            parentOfParent.classList.add('active');
          }
        }
      }
    }
  }

  const toggleMenu = () => {
    document.getElementById('isToggle').classList.toggle('open');
    var isOpen = document.getElementById('navigation')
    if (isOpen.style.display === "block") {
      isOpen.style.display = "none";
    } else {
      isOpen.style.display = "block";
    }
  };

  const routeChange = () =>{ 
    let path = `https://spatial.io/s/Art-NFT-62dfbb361c41e70001aa656b?share=7911456489225020587`; 
    window.open(path, '_blank').focus();
  }

  return (
    <>
      {/* Navbar STart */}
      <header
        id="topnav"
        className={`defaultscroll sticky ${url && 'gradient'}`}
      >
        <div className="container">
          {/* Logo Start*/}
          <a
            className="logo py-1"
            href="/token-sports"
            onClick={e => {
              e.preventDefault()
              navigate('/token-sports')
              setTimeout(() => {
                activateMenu()
                toggleSwitcher(false)
              }, 1000)
            }}
          >
            <span className="">
              <img
                src={logoDark}
                height="60"
                className={becomeUrl ? 'logo-light-mode' : 'l-dark'}
                alt=""
              />
              <img
                src={logoDark}
                height="60"
                className={becomeUrl ? 'logo-dark-mode' : 'l-light'}
                alt=""
              />
            </span>
            {/* {!becomeUrl && (
              <img
                src={lightLogo}
                height="26"
                className="logo-dark-mode"
                alt=""
              />
            )} */}
          </a>
          {/* Logo end*/}

          {/* Mobile */}
          <div className="menu-extras">
            <div className="menu-item">
              {/* Mobile menu toggle*/}
              <a
                className="navbar-toggle"
                id="isToggle"
                onClick={e => {
                  e.preventDefault()
                  toggleMenu()
                }}
              >
                <div className="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
              {/* End mobile menu toggle*/}
            </div>
          </div>
          {/* Mobile */}

          {/*Login button Start*/}

          <ul className="buy-button list-inline mb-0">

          <li className="list-inline-item mb-0 me-1 hide">
                <button
                  
                  onClick={routeChange}
                  className="sub-menu-item btn-header btn btn-pills btn-outline-light-white-2 mt-3 fs-12"
                >
                  Visit Metaverse
                </button>
              </li>

          <li className="list-inline-item mb-0 me-1 hide">
  <a
                 
                  className="sub-menu-item btn-header btn btn-pills btn-outline-light-white-2 mt-3 fs-12"
                 href="https://www.tokenarts.io/"
                 target="_blank" rel="noreferrer"
                >
                Visit TokenArts Fine Arts Investment
                </a>
 </li>

            <li className="list-inline-item mb-0 me-1">
              <div className="dropdown">
                <button
                  type="button"
                  className="btn dropdown-toggle p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {becomeUrl ? (
                    <i className="uil uil-search text-dark fs-5 align-middle"></i>
                  ) : (
                    <>
                      <i className="uil uil-search text-white title-dark btn-icon-light fs-5 align-middle"></i>
                      <i className="uil uil-search text-dark btn-icon-dark fs-5 align-middle"></i>
                    </>
                  )}
                </button>
                <div
                  className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-0"
                  style={{ width: 300 }}
                >
                  <div className="search-bar">
                    <div id="itemSearch" className="menu-search mb-0">
                      <form
                        role="search"
                        method="get"
                        id="searchItemform"
                        className="searchform"
                      >
                        <input
                          type="text"
                          className="form-control border rounded"
                          name="s"
                          id="searchItem"
                          placeholder="Search..."
                        />
                        <input
                          type="submit"
                          id="searchItemsubmit"
                          value="Search"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            
            <li className="list-inline-item mb-0 me-1">
              {becomeUrl ? (
                <a
                  id="connectWallet"
                  className="btn btn-icon btn-pills btn-primary"
                  onClick={_handleConnectWallet}
                >
                  <i className="uil uil-wallet fs-6"></i>
                </a>
              ) : (
                <p id="connectWallet" onClick={_handleConnectWallet}>
                  <span className="btn-icon-dark">
                    <span className="btn btn-icon btn-pills btn-primary">
                      <i className="uil uil-wallet fs-6"></i>
                    </span>
                  </span>
                  <span className="btn-icon-light">
                    <span className="btn btn-icon btn-pills btn-light">
                      <i className="uil uil-wallet fs-6"></i>
                    </span>
                  </span>
                </p>
              )}
            </li>
 
            
            {user !== null?
        ( 
            <li className="list-inline-item mb-0">
              <div className="dropdown dropdown-primary">
                <button
                  type="button"
                  className="btn btn-pills dropdown-toggle p-0"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >

                  <img
                    src={ele}
                    className="rounded-pill avatar avatar-sm-sm"
                    alt=""
                  />
                </button>
                <div
                  className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 pb-3 pt-0 overflow-hidden rounded"
                  style={{ minWidth: 200 }}
                >
                  <div className="position-relative">
                    <div className="pt-5 pb-3 bg-gradient-primary"></div>
                    <div className="px-3">
                      <div className="d-flex align-items-end mt-n4">
                        <img
                          src={ele}
                          className="rounded-pill avatar avatar-md-sm img-thumbnail shadow-md"
                          alt=""
                        />
                        <h6 className="text-dark fw-bold mb-0 ms-1  text-limit-1">
                        {userdisplay}
                        </h6>
                      </div>
                      <div className="mt-2">
                        <small className="text-start text-dark d-block fw-bold">
                          Wallet:
                        </small>
                        <div className="d-flex justify-content-between align-items-center">
                          <small id="myPublicAddress" className="text-muted text-limit-1">
                            {myPublicAddress}
                          </small>
                          <a href="" onClick={e => e.preventDefault()} className="text-primary">
                            <span className="uil uil-copy"></span>
                          </a>
                        </div>
                      </div>

                      <div className="mt-2">
                        <small className="text-dark">
                          Balance:{' '}
                          <span className="text-primary fw-bold  text-limit-1">
                           {balanceeth}
                          </span>
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <a
                      className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                      href="/creator-profile"
                      onClick={e => {
                        e.preventDefault()
                        navigate('/creator-profile')
                      }}
                    >
                      <span className="mb-0 d-inline-block me-1">
                        <i className="uil uil-user align-middle h6 mb-0 me-1"></i>
                      </span>{' '}
                      Profile
                    </a>


                    <a
                      className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                      href="/upload-work"
                      onClick={e => {
                        e.preventDefault()
                        navigate('/upload-work')
                      }}
                    >
                      <span className="mb-0 d-inline-block me-1">
                        <i className="uil uil-upload align-middle h6 mb-0 me-1"></i>
                      </span>{' '}
                      Upload Work
                    </a>

                    <a
                      className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                      href="/creator-profile-edit"
                      onClick={e => {
                        e.preventDefault()
                        setTimeout(() => {
                          activateMenu()
                          toggleSwitcher(false)
                        }, 1000)
                        navigate('/creator-profile-edit')
                      }}
                    >
                      <span className="mb-0 d-inline-block me-1">
                        <i className="uil uil-cog align-middle h6 mb-0 me-1"></i>
                      </span>{' '}
                      Settings
                    </a>
                    <div className="dropdown-divider border-top"></div>
                    <a
                      className="dropdown-item small fw-semibold text-dark d-flex align-items-center"
                      href="/lock-screen"
                      onClick={e => {
                        e.preventDefault()
                        // setTimeout(() => {
                        //   activateMenu()
                        //   toggleSwitcher(false)
                        // }, 1000)
                        //navigate('/lock-screen')
                        window.localStorage.removeItem('token');
                        navigate('/login')
                      }}
                    >
                      <span className="mb-0 d-inline-block me-1">
                        <i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i>
                      </span>{' '}
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </li>):(
              <li className="list-inline-item mb-0">
              <div className="dropdown dropdown-primary">
                <a
                              href="/login"
                              onClick={e => {
                                e.preventDefault()
                                navigate('/login')
                              }}
                              className="btn btn-pills btn-outline-dark"
                            >
                             Login
                            </a>
              </div>
              </li> 
            )

}


          </ul>
          {/*Login button End*/}

          <div id="navigation">
            {/* Navigation Menu*/}
            <ul
              className={`navigation-menu nav-left ${!becomeUrl && 'nav-light'
                }`}
            >
              

             {/*} <li>
                <a
                  href="/aboutus"
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                      activateMenu()
                      toggleSwitcher(false)
                    }, 1000)
                    navigate('/aboutus')
                  }}
                  className="sub-menu-item"
                >
                  {' '}
                  About
                </a>
                </li>*/}

              <li>
                <a
                  href="/explore-two"
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                      activateMenu()
                      toggleSwitcher(false)
                    }, 1000)
                    navigate('/explore-two')
                  }}
                  className="sub-menu-item"
                >
                  {' '}
                  Marketplace
                </a>
              </li>

              


             

            
              {user !== null?
        ( 
              <li>
                <a
                  href="/mynfts"
                  onClick={e => {
                    e.preventDefault()
                    setTimeout(() => {
                      activateMenu()
                      toggleSwitcher(false)
                    }, 1000)
                    navigate('/mynfts')
                  }}
                  className="sub-menu-item"
                >
                  My NFTs
                </a>
              </li>
        ):("")}

             


            
             
            </ul>
            {/*end navigation menu*/}
          </div>
          {/*end navigation*/}
        </div>
        {/*end container*/}
      </header>
      {/*end header*/}
      {/* Navbar End */}
    </>
  )
}

export default Navbar
