import React,{useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import { FiCamera } from 'react-icons/fi'
import Countdown from 'react-countdown'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import StyleSwitcher from '../../components/StyleSwitcher'
import { ethers } from 'ethers'
import { useEffect, useState } from 'react'
import web3 from 'web3'
import axios from 'axios'
import Web3Modal from "web3modal"
import Market from '../../artifacts/contracts/NFTMarket.sol/NFTMarket.json'
import NFT from '../../artifacts/contracts/NFT.sol/NFT.json'
import {NFTContext} from "../../context/NFTContext";
import {
  nftmarketaddress, nftaddress
} from '../../config'
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Jazzicon from '@raugfer/jazzicon';
import {
  client01, client02, client03, client04, client05, client06, client08,
  client10, client12, client13,
  gif1, gif2, gif3, gif4, gif5, gif6,
  item1, item2, item3, item4, item5, item6, item7, item8, item9, item10,
  single, ofcDesk, prodToCard, ico08,
} from '../../components/imageImport'

const CreateProfile = () => {
  const navigate = useNavigate()
  const [ele, setEle] = useState('')
  const [nfts, setNfts] = useState([])
  const [loaded, setLoaded] = useState('not-loaded')
  const [loading, SetLoading] = useState(true);

  /*
  async function loadNFTs() {
    const web3Modal = new Web3Modal({
      network: "https://eth-goerli.alchemyapi.io/v2/9ctKkVZf-pCbOXxV3hT-2OhSm97FAmJg",
      cacheProvider: true,
    });
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()
      
    const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
    const data = await marketContract.fetchMyNFTs()

    const items = await Promise.all(data.map(async i => {
      const tokenUri = await tokenContract.tokenURI(i.tokenId)
      const meta = await axios.get(tokenUri)
      let price = web3.utils.fromWei(i.price.toString(), 'ether');
      let item = {
        price,
        tokenId: i.tokenId.toNumber(),
        seller: i.seller,
        owner: i.owner,
        image: meta.data.image,
      }
      return item
    }))
    console.log('items: ', items)
    setNfts(items)
    setLoaded('loaded') 
  }

*/

//=========================================all nfts

const [nftsall, setNftsall] = useState([])
const [loadedall, setLoadedall] = useState('not-loaded')
const [currentAccount, setCurrentAccount] = useState(null);
const [all, setAll] = useState([])







const checkWalletIsConnected = async () => {
  const { ethereum } = window;

  if (!ethereum) {
    console.log("Make sure you have Metamask installed!");
    return;
  } else {
    console.log("Wallet exists! We're ready to go!")
  }

  const accounts = await ethereum.request({ method: 'eth_accounts' });

  if (accounts.length !== 0) {
    const account = accounts[0];
    console.log("Found an authorized account: ", account);
    setCurrentAccount(account);
  } else {
    console.log("No authorized account found");
  }
}

const connectWalletHandler = async () => {
  const { ethereum } = window;

  if (!ethereum) {
    alert("Please install Metamask!");
  }

  try {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    console.log("Found an account! Address: ", accounts[0]);
    setCurrentAccount(accounts[0]);
  } catch (err) {
    console.log(err)
  }
}


const connectWalletButton = () => {
  return (
    <button onClick={connectWalletHandler} className='cta-button connect-wallet-button'>
      Connect Wallet
    </button>
  )
}

const { fetchMyNFTsOrCreatedNFTs,createSale, isLoadingNFT} = useContext(NFTContext);
const [price, setPrice] = useState('');
useEffect(() => {
  //loadNFTsall()
  fetchMyNFTsOrCreatedNFTs('fetchItemsListed')
  .then((item) => {
    setNftsall(item);
    console.log('items',item)
   // setNftsCopy(items);
   // setIsLoading(false);
   SetLoading(false);
  });


  checkWalletIsConnected();
}, [])


async function createMarketSale(address,id) {
  const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
  });
  const connection = await web3Modal.connect()
  const provider = new ethers.providers.Web3Provider(connection)    
  const signer = provider.getSigner()
  
//createMarketSale
 
  const listingPrice = web3.utils.toWei('0.01', 'ether')
  let contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
  let transaction = await contract.createMarketSale(address, id,{ price: listingPrice,tokenId:id })
  
  await transaction.wait()
 
 // router.push('/')
}



async function loadNFTsall() {
  const url = "https://eth-goerli.alchemyapi.io/v2/9ctKkVZf-pCbOXxV3hT-2OhSm97FAmJg";
  const provider = new ethers.providers.JsonRpcProvider(url)
  const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
  const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, provider)
  const data = await marketContract.fetchMarketItems()
  

  


  const items = await Promise.all(data.map(async i => {
    const tokenUri = await tokenContract.tokenURI(i.tokenId)
    const meta = await axios.get(tokenUri)
    let price = web3.utils.fromWei(i.price.toString(), 'ether');
    let item = {
      price,
      tokenId: i.tokenId.toNumber(),
      seller: i.seller,
      owner: i.owner,
      image: meta.data.image,
      title: meta.data.name,
    }
    return item
  }))
  console.log('items: ', items)
  setNftsall(items)
  setLoadedall('loaded') 
}
async function buyNft(nft) {
  const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
  });
  const connection = await web3Modal.connect()
  const provider = new ethers.providers.Web3Provider(connection)
  const signer = provider.getSigner()
  const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
  
  const price = web3.utils.toWei(nft.price.toString(), 'ether');

  console.log('price: ', price);
  
  const transaction = await contract.createMarketSale(nftaddress, nft.tokenId, {
    value: price
  })
  await transaction.wait()
  loadNFTsall()
}


///end

  const loadFile = function (event) {
    var image = document.getElementById(event.target.name)
    image.src = URL.createObjectURL(event.target.files[0])
  }

  useEffect(() => {
    if (localStorage.getItem("token") === null) {
     // useNavigate('/login')
      navigate('/login');
    }
   
  },[])

  function buildDataUrl(address: string): string {
    return 'data:image/svg+xml;base64,' + btoa(Jazzicon(address));
  }


  const {nftCurrency, connectWallet,checkWalletConnected,fetchNFTs} =useContext(NFTContext);

  const resell = async (tokenURI, price, id) => {
    await createSale(tokenURI, price, true, id);
    localStorage.setItem('resell'+id, id);

  
  };

  useEffect(() => {
    var body = document.querySelector('body')
   // for(var i = 0; i < 60; i++) {
    const imageUrl = buildDataUrl('0xC871D4F6cFAc76b2c8b3a4F9a821279DD80e4813');
    
      setEle(imageUrl);

      fetchMyNFTsOrCreatedNFTs('fetchMyNFTs')
      .then((items) => {
        setNfts(items);
        setLoaded('loaded') ;
      //  setNftsCopy(items);
      //  setIsLoading(false);
      });



      fetchNFTs()
      .then((items) => {
        setAll(items.reverse());
      
       // setIsLoading(false);
      });


     
     // body.appendChild(el)
  //  }
  }, [])

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start Home */}
      <section className="bg-creator-profile">
        <div className="container">
          <div className="profile-banner">
            <input
              id="pro-banner"
              name="profile-banner"
              type="file"
              className="d-none"
              onChange={e => loadFile(e)}
            />
            <div className="position-relative d-inline-block">
              <img
                src={single}
                className="rounded-md shadow-sm img-fluid"
                id="profile-banner"
                alt=""
              />
              <label
                className="icons position-absolute bottom-0 end-0"
                htmlFor="pro-banner"
              >
                <span className="btn btn-icon btn-sm btn-pills btn-primary">
                  <FiCamera className="icons" />
                </span>
              </label>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col">
              <div className="text-center mt-n80">
                <div className="profile-pic">
                  <input
                    id="pro-img"
                    name="profile-image"
                    type="file"
                    className="d-none"
                    onChange={e => loadFile(e)}
                  />
                  <div className="position-relative d-inline-block">
                    <img
                      src={ currentAccount != null ?  buildDataUrl(currentAccount):ele}
                      className="avatar avatar-medium img-thumbnail rounded-pill shadow-sm"
                      id="profile-image"
                      alt=""
                    />
                    <label
                      className="icons position-absolute bottom-0 end-0"
                      htmlFor="pro-img"
                    >
                      <span className="btn btn-icon btn-sm btn-pills btn-primary">
                        <FiCamera className="icons" />
                      </span>
                    </label>
                  </div>
                </div>

                <div className="content mt-3">
                  <h5 className="mb-3">{localStorage.getItem('name')}</h5>
                  <small className="text-muted px-2 py-1 rounded-lg shadow">
                   {currentAccount != null ?currentAccount :connectWalletButton()}
                    <a
                      href=""
                      onClick={e => e.preventDefault()}
                      className="text-primary h5 ms-1"
                    >
                      <i className="uil uil-copy"></i>
                    </a>
                  </small>

                  <h6 className="mt-3 mb-0">
                    {/* Artist, UX / UI designer, and Entrepreneur */}
                  </h6>

                  <div className="mt-4">
                    <a
                      href="/creator-profile-edit"
                      onClick={e => {
                        e.preventDefault()
                        navigate('/creator-profile-edit')
                      }}
                      className="btn btn-pills btn-outline-primary mx-1"
                    >
                      Edit Profile
                    </a>
                    <a
                      href="/upload-work"
                      onClick={e => {
                        e.preventDefault()
                        navigate('/upload-work')
                      }}
                      className="btn btn-pills btn-icon btn-outline-primary mx-1"
                    >
                      <i className="uil uil-folder-upload"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row">
            <div className="col-12">
              <ul
                className="nav nav-tabs border-bottom"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="Create-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#CreateItem"
                    type="button"
                    role="tab"
                    aria-controls="CreateItem"
                    aria-selected="true"
                  >
                    Created
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Liked-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Liked"
                    type="button"
                    role="tab"
                    aria-controls="Liked"
                    aria-selected="false"
                  >
                    Liked
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Sale-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Sale"
                    type="button"
                    role="tab"
                    aria-controls="Sale"
                    aria-selected="false"
                  >
                    On Sale
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Collection-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Collection"
                    type="button"
                    role="tab"
                    aria-controls="Collection"
                    aria-selected="false"
                  >
                   My Collection
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Activites-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Activites"
                    type="button"
                    role="tab"
                    aria-controls="Activites"
                    aria-selected="false"
                  >
                    Activites
                  </button>
                </li>

                {/*<li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="Followers-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#Followers"
                    type="button"
                    role="tab"
                    aria-controls="Followers"
                    aria-selected="false"
                  >
                    Followers
                  </button>
                    </li>*/}

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="About-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#About"
                    type="button"
                    role="tab"
                    aria-controls="About"
                    aria-selected="false"
                  >
                    About
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                   
                   
                    type="button"
                    role="tab"
                    onClick={(e)=>{navigate('/upload-work')}}
                 
                  >
                    Upload Work
                  </button>
                </li>
                

                
              </ul>

              <div className="tab-content mt-4 pt-2" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="CreateItem"
                  role="tabpanel"
                  aria-labelledby="Create-tab"
                >
                  {/* if value select created */}
                  <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4">

                   
                    { nftsall?.map(data => {

 return localStorage.getItem('resell'+data.tokenId) !=data.tokenId ?

                    (  <div className="col" key={data?.name}>
                        <div className="card nft-items nft-default rounded-md shadow overflow-hidden mb-1 p-3">
                        
                          <div className="nft-image rounded-md mt-0 position-relative overflow-hidden">
                            <a
                              href="/item-detail-one"
                              onClick={e => {
                                e.preventDefault()
                                navigate(`/item-detail-one?${ base64_encode(JSON.stringify(data))}`)
                              }}
                            >
                              <img
                                src={data?.image}
                                className="img-fluid"
                                alt=""
                              />
                            </a>
                            {data?.type && (
                              <div className="position-absolute top-0 start-0 m-2">
                                <a
                                  href=""
                                  onClick={e => e.preventDefault()}
                                  className="badge badge-link bg-primary"
                                >
                                  GIFs
                                </a>
                              </div>
                            )}
                            <div className={`${data?.id ? '' : 'hide-data'} position-absolute bottom-0 start-0 m-2 bg-gradient-primary text-white title-dark rounded-pill px-3`}>
                              <i className="uil uil-clock"></i>
                              <Countdown
                                date={data?.id}
                                renderer={({ days, hours, minutes, seconds }) => (
                                  <span>
                                    {days}:{hours}:{minutes}:{seconds}
                                  </span>
                                )}
                              />
                            </div>
                          </div>

                          <div className="card-body content position-relative p-0 mt-3">
                            <a
                              href="/item-detail-one"
                              onClick={e => {
                                e.preventDefault()
                                navigate(`/item-detail-one?${ base64_encode(JSON.stringify(data))}`)
                              }}
                              className="title text-dark h6"
                            >
                              {data?.name}
                            </a>

                            <div className="d-flex justify-content-between mt-2">
                              <small className="rate fw-bold">{data?.price} ETH</small>
                              <div className="item-right-content">
                                    <a
                                      className="btn btn-icon btn-pills btn-outline-light-white me-2"
                                      href={`https://goerli.etherscan.io/token/0x7725Ae255675884d5545218De9Ba5b477dC84999`}
                                      title="View On PolygonScan"
                                      alt="View On PolygonScan"
                                    >
                                      <img
                                        style={{ width: "10px" }}
                                        src={ico08}
                                      />
                                    </a>

                                  </div>
                            </div>
                          </div>
                        </div>
                      </div>):''


                            })}
                    {/*end row*/}
                  </div>
                </div>
                {/* if value select like */}
                <div
                  className="tab-pane fade"
                  id="Liked"
                  role="tabpanel"
                  aria-labelledby="Liked-tab"
                >
                  <div className="row justify-content-center">
                   

                      <div className="content">
                        {/* if value select created */}
                  <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4">

                   
{ all?.map(data => {
return localStorage.getItem(data.tokenId)===currentAccount?

  (<div className="col" key={data?.name}>
    <div className="card nft-items nft-default rounded-md shadow overflow-hidden mb-1 p-3">
    
      <div className="nft-image rounded-md mt-0 position-relative overflow-hidden">
        <a
          href="/item-detail-one"
          onClick={e => {
            e.preventDefault()
            navigate(`/item-detail-one?${ base64_encode(JSON.stringify(data))}`)
          }}
        >
          <img
            src={data?.image}
            className="img-fluid"
            alt=""
          />
        </a>
        {data?.type && (
          <div className="position-absolute top-0 start-0 m-2">
            <a
              href=""
              onClick={e => e.preventDefault()}
              className="badge badge-link bg-primary"
            >
              GIFs
            </a>
          </div>
        )}
       
      </div>

      <div className="card-body content position-relative p-0 mt-3">
        <a
          href="/item-detail-one"
          onClick={e => {
            e.preventDefault()
            navigate(`/item-detail-one?${ base64_encode(JSON.stringify(data))}`)
          }}
          className="title text-dark h6"
        >
          {data?.name}
        </a>

        <div className="d-flex justify-content-between mt-2">
          <small className="rate fw-bold">{data?.price} ETH</small>
          <div className="item-right-content">
                <a
                  className="btn btn-icon btn-pills btn-outline-light-white me-2"
                  href={`https://goerli.etherscan.io/token/0x7725Ae255675884d5545218De9Ba5b477dC84999`}
                  title="View On PolygonScan"
                  alt="View On PolygonScan"
                >
                  <img
                    style={{ width: "10px" }}
                    src={ico08}
                  />
                </a>

              </div>
        </div>
      </div>
    </div>
  </div>):''


        })}
</div>
                       
                     
                    </div>
                    {/* end col */}
                  </div>
                  {/* end row */}
                </div>
                {/* if value select on sale */}
                <div
                  className="tab-pane fade"
                  id="Sale"
                  role="tabpanel"
                  aria-labelledby="Sale-tab"
                >
                  <div className="">




{/* modified  */}

 {(loaded === 'loaded' && !nfts.length) ? (''):(

  <div class="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4">
          {nftsall.map((nft, i) => (
              <div className="pt-2 mt-4" key={i}>
                <div className="card nft-items nft-default rounded-md shadow overflow-hidden mb-1 p-3">
                  <div className="nft-image rounded-md mt-0 position-relative overflow-hidden">
                    <img src={nft.image} class="img-fluid" alt="" />
                  </div>
              
                  <div className="card-body content position-relative p-0 mt-3">
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`/item-detail-one?${ base64_encode(JSON.stringify(nft))}`);
                      }}
                      className="title text-dark h6"
                    >
                      {nft.name}
                    </a>
                    <div className="d-flex justify-content-between mt-2">
                      <div className="item-left-content">
                        <small className="rate fw-bold">{nft.price} ETH</small>
                      </div>
                      <div className="item-right-content">
                        <a
                          className="btn btn-icon btn-pills btn-outline-light-white me-2"
                          href=""
                          title="View On PolygonScan"
                          alt="View On PolygonScan"
                        >
                          <img
                            style={{ width: "10px" }}
                            src={ico08}
                          />
                        </a>
                        <button
                          className="btn btn-icon btn-pills btn-outline-light-white "
                          onClick={() => buyNft(nft)}
                        >
                          <i className="uil uil-shopping-cart fs-6"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                
            ))
          }
      
     </div>
  
  )}




{/* end modified */}










                    
              
                  </div>
                  {/*end row*/}
                </div>
                {/* if value select collection */}
                <div
                  className="tab-pane fade "
                  id="Collection"
                  role="tabpanel"
                  aria-labelledby="Collection-tab"
                >
                  <div className="">

                    
                 { (loaded === 'loaded' && !nfts.length )?'':(

               
                    <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4">
                      {
                        nfts.map((nft, i) => (
                          <div className="pt-2 mt-4" key={i}>
                          <div className="card nft-items nft-default rounded-md shadow overflow-hidden mb-1 p-3">
                            <div className="nft-image rounded-md mt-0 position-relative overflow-hidden">
                            <a
                                 href="/item-detail-two"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/item-detail-two?${ base64_encode(JSON.stringify(nft))}`);
                              }}
                              className="title text-dark h6"
                            >
                                <img src={nft.image} class="img-fluid" alt="" />
                                </a>
                            </div>  
                            <div className="card-body content position-relative p-0 mt-3">
                               
                                <a
                                 href="/item-detail-two"
                              onClick={(e) => {
                                e.preventDefault();
                                navigate(`/item-detail-two?${ base64_encode(JSON.stringify(nft))}`);
                              }}
                              className="title text-dark h6"
                            >
                              {nft.name}
                            </a>

                                <div className="d-flex justify-content-between mt-2">
                                  <div className="item-left-content">
                                    <small className="rate fw-bold">
                                    {nft.price} ETH
                                    </small>
                                  </div>
                                  <div className="item-right-content">
                                    <a
                                      className="btn btn-icon btn-pills btn-outline-light-white me-2"
                                      href={`https://goerli.etherscan.io/token/0x7725Ae255675884d5545218De9Ba5b477dC84999`}
                                      title="View On PolygonScan"
                                      alt="View On PolygonScan"
                                    >
                                      <img
                                        style={{ width: "10px" }}
                                        src={ico08}
                                      />
                                    </a>

                                  </div>
                                </div> 

                                <div className="d-flex justify-content-center mt-2 px-3">
                                  <input type="text" className="input-txt-3"  inputType="number"
                                  title="Price"
                                  placeholder=""
                                  onChange={(e) => setPrice(e.target.value)}
                                  
                                  ></input>
                                  <button onClick={()=>{resell(nft.tokenURI, price, nft.tokenId)}} className="btn-pills btn-sm btn-danger bg-blue-600 py-2 px-12 text-white m-16 ms-1">Resell</button>
                                </div> 

                              </div>
                            </div>
                          



                          </div>
                        ))
                      }
                    </div>


                 )}
                <div className="col-md-3 col-sm-10">
                 { (loaded === 'not-loaded' && !nfts.length)?(<button onClick={()=>{}} className="btn btn-pills btn-soft-primary py-2 px-12 text-white m-16 w-100">Fetch NFTs</button>):''}
                 </div>
                <div className="col-md-12 col-sm-12">
                    {isLoadingNFT?<div className="text-center"><div class="lds-facebook"><div></div><div></div><div></div></div><p className="text-gradient-info animate-charcter">PLEASE CHEK YOUR WALLET...</p></div>:''}
                </div>
                    {/* <div className="col-lg-5 col-md-8 text-center"> */}
                      {/* <img src={prodToCard} className="img-fluid" alt="" /> */}

                      {/* <div className="content mt-4">
                        <h5 className="mb-4">No Collection</h5>
                        <p className="text-muted">
                          Save interesting shots into personalized collections,
                          and discover new and interesting recommendations along
                          the way.
                        </p>
                      </div> */}
                    {/* </div> */}


                    {/*end col*/}
                  </div>
                  {/*end row*/}
                </div>
                {/* if value select follower */}
              
                {/* if value select activity */}
                <div
                  className="tab-pane fade"
                  id="Activites"
                  role="tabpanel"
                  aria-labelledby="Activites-tab"
                >
                  <div className="row g-4">
                  {loading?<div className="text-center"><div class="lds-facebook"><div></div><div></div><div></div></div></div>:''}
                   
  {/* {JSON.stringify(nfts)}
  {JSON.stringify(all)} */}


                    { nfts?.map(data => {
                      return (
                        <div className="col-12" key={data?.title}>
                          <div className="card activity activity-primary rounded-md shadow p-4">
                            <div className="d-flex align-items-center">
                              <div className="position-relative">
                                <img
                                  src={data?.image}
                                  className="img-fluid avatar avatar-md-md rounded-md shadow-md w-100"
                                  alt=""
                                />

                                {/* <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white">
                                  {data?.favorite === 'Started Following' ? (
                                    <i className="mdi mdi-account-check mdi-18px text-success"></i>
                                  ) : data?.favorite === 'Liked by' ? (
                                    <i className="mdi mdi-heart mdi-18px text-danger"></i>
                                  ) : (
                                    <i className="mdi mdi-format-list-bulleted mdi-18px text-warning"></i>
                                  )}
                                </div>
                                <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"></div>
                                <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"></div> */}
                              </div>

                            <span className="content ms-3">
                                <a
                                  href=""
                                  onClick={e => e.preventDefault()}
                                  className="text-dark title mb-0 h6 d-block"
                                >
                               
                                </a>
                                <small className="text-muted d-block mt-1">
                               
                                  <a
                                    href=""
                                    onClick={e => e.preventDefault()}
                                    className="link fw-bold"
                                  >
                                    @{data?.seller}
                                  </a>
                                </small>

                                <small className="text-muted d-block mt-1">
                              
                                NFT Bought
                                </small>
                              </span> 
                            </div>
                          </div>
                        </div>
                      )
                    })}



{ nftsall?.map(data => {
                      return (
                        <div className="col-12" key={data?.title}>
                          <div className="card activity activity-primary rounded-md shadow p-4">
                            <div className="d-flex align-items-center">
                              <div className="position-relative">
                                <img
                                  src={data?.image}
                                  className="img-fluid avatar avatar-md-md rounded-md shadow-md w-100"
                                  alt=""
                                />

                                {/* <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white">
                                  {data?.favorite === 'Started Following' ? (
                                    <i className="mdi mdi-account-check mdi-18px text-success"></i>
                                  ) : data?.favorite === 'Liked by' ? (
                                    <i className="mdi mdi-heart mdi-18px text-danger"></i>
                                  ) : (
                                    <i className="mdi mdi-format-list-bulleted mdi-18px text-warning"></i>
                                  )}
                                </div>
                                <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"></div>
                                <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"></div> */}
                              </div>

                            <span className="content ms-3">
                                <a
                                  href=""
                                  onClick={e => e.preventDefault()}
                                  className="text-dark title mb-0 h6 d-block"
                                >
                               
                                </a>
                                <small className="text-muted d-block mt-1">
                               
                                  <a
                                    href=""
                                    onClick={e => e.preventDefault()}
                                    className="link fw-bold"
                                  >
                                    @{data?.seller}
                                  </a>
                                </small>

                                <small className="text-muted d-block mt-1">
                              
                                NFT Listed
                                </small>
                              </span> 
                            </div>
                          </div>
                        </div>
                      )
                    })}



{ all?.map(data => {
//return localStorage.getItem('resell'+data.tokenId) ===data.tokenId ?
 // data?.seller===currentAccount  ?
 return  localStorage.getItem('resell'+data.tokenId) !='' &&  parseInt(localStorage.getItem('resell'+data.tokenId)) ===parseInt(data.tokenId) ? (
                        <div className="col-12" key={data?.title}>
                          <div className="card activity activity-primary rounded-md shadow p-4">
                            <div className="d-flex align-items-center">
                              <div className="position-relative">
                                <img
                                  src={data?.image}
                                  className="img-fluid avatar avatar-md-md rounded-md shadow-md w-100"
                                  alt=""
                                />

                                {/* <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white">
                                  {data?.favorite === 'Started Following' ? (
                                    <i className="mdi mdi-account-check mdi-18px text-success"></i>
                                  ) : data?.favorite === 'Liked by' ? (
                                    <i className="mdi mdi-heart mdi-18px text-danger"></i>
                                  ) : (
                                    <i className="mdi mdi-format-list-bulleted mdi-18px text-warning"></i>
                                  )}
                                </div>
                                <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"></div>
                                <div className="position-absolute top-0 start-0 translate-middle px-1 rounded-lg shadow-md bg-white"></div> */}
                              </div>

                            <span className="content ms-3">
                                <a
                                  href=""
                                  onClick={e => e.preventDefault()}
                                  className="text-dark title mb-0 h6 d-block"
                                >
                               
                                </a>
                                <small className="text-muted d-block mt-1">
                               
                                  <a
                                    href=""
                                    onClick={e => e.preventDefault()}
                                    className="link fw-bold"
                                  >
                                    @{data?.seller}
                                  </a>
                                </small>

                                <small className="text-muted d-block mt-1">
                              
                                NFT Sold
                                </small>
                              </span> 
                            </div>
                          </div>
                        </div>
                     ) :''

                              }
                  
                  )}



                  </div>
                </div>
                {/* if value select about */}
                <div
                  className="tab-pane fade"
                  id="About"
                  role="tabpanel"
                  aria-labelledby="About-tab"
                >
                  <h5 className="mb-4">Calvin Carlo</h5>

                  <p className="text-muted mb-0">
                    I have started my career as a trainee and prove my self and
                    achieve all the milestone with good guidance and reach up to
                    the project manager. In this journey, I understand all the
                    procedure which make me a good developer, team leader, and a
                    project manager.
                  </p>
                </div>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Home */}
      {/* footer */}
      <Footer />

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  )
}

export default CreateProfile
