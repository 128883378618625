import React, { useEffect, useState ,useContext} from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import StyleSwitcher from "../../components/StyleSwitcher";
import Countdown from "react-countdown";
import {NFTContext} from "../../context/NFTContext";
import { ethers } from "ethers";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Jazzicon from '@raugfer/jazzicon';
import {
  client01,
  client02,
  client03,
  client08,
  client09,
  client10,
  ico08,
  itemDetail1,
} from "../../components/imageImport";
import {
  bg01,
  item1,
  item2,
  item3,
  item4,
  item5,
  item6,
  item7,
  item8,
  item9,
  item10,
  gif1,
  gif2,
  gif3,
  gif4,
  gif5,
  gif6,
  cta,
  client05,
  client06,
} from "../../components/imageImport";


import web3 from "web3";
import axios from "axios";
import Web3Modal from "web3modal";
import Market from "../../artifacts/contracts/NFTMarket.sol/NFTMarket.json";
import NFT from "../../artifacts/contracts/NFT.sol/NFT.json";
import { nftmarketaddress, nftaddress } from "../../config";

import { shortenAddress } from '../../util/shortenAddress';

const ItemDetailTwo = () => {
  const navigate = useNavigate();
  const [rate, setRate] = useState(1);

  const { nftCurrency, buyNft, currentAccount, isLoadingNFT,fetchNFTs ,createSale} = useContext(NFTContext);
  const [nft, setNft] = useState([]);

  const activityData = [
    {
      title: "Digital Art Collection",
      author: "Panda",
      time: "1 hours ago",
      favorite: "Started Following",
      image: item1,
    },
    {
      title: "Skrrt Cobain Official",
      author: "ButterFly",
      time: "2 hours ago",
      favorite: "Liked by",
      image: gif1,
    },
    {
      title: "Wow! That Brain Is Floating",
      author: "ButterFly",
      time: "2 hours ago",
      favorite: "Liked by",
      image: item2,
    },
  ];
  const createdData = [
    {
      image: gif1,
      title: "Deep Sea Phantasy",
      id: "May 29, 2022 6:0:0",
      type: "GIFs",
      client: client01,
      author: "StreetBoy",
    },
    {
      image: item1,
      title: "CyberPrimal 042 LAN",
      id: "June 03, 2022 5:3:1",
      type: "Arts",
      client: client09,
      author: "PandaOne",
    },
    {
      image: gif2,
      title: "Crypto Egg Stamp #5",
      id: "June 10, 2022 1:0:1",
      type: "GIFs",
      client: client02,
      author: "CutieGirl",
    },
    {
      image: item2,
      title: "Colorful Abstract Painting",
      id: "June 18, 2022 1:2:1",
      type: "Memes",
      client: client03,
      author: "NorseQueen",
    },
  ];

  const AuctionData = [
    {
      image: item1,
      title: "CyberPrimal 042 LAN",
      id: "",
      type: "Arts",
      hash: 1,
      price: 0.001,
    },
    {
      image: gif2,
      title: "Crypto Egg Stamp #5",
      id: "",
      type: "Games",
      hash: 2,
      price: 0.004,
    },
    {
      image: item2,
      title: "Colorful Abstract Painting",
      id: "June 03, 2022 5:3:1",
      type: "",
      hash: 3,
      price: 0.005,
    },
    {
      image: item3,
      title: "Liquid Forest Princess",
      id: "",
      type: "",
      hash: 4,
      price: 0.006,
    },
    {
      image: gif3,
      title: "Spider Eyes Modern Art",
      id: "June 10, 2022 1:0:1",
      type: "GIFs",
      hash: 5,
      price: 0.005,
    },
    {
      image: item4,
      title: "Synthwave Painting",
      id: "",
      type: "",
      hash: 6,
      price: 0.004,
    },
    {
      image: item5,
      title: "Valkyrie Abstract Art",
      id: "",
      type: "",
      hash: 7,
      price: 0.005,
    },
    {
      image: item6,
      title: "Dodo hide the seek",
      id: "",
      type: "",
      hash: 8,
      price: 0.0034,
    },
    {
      image: item7,
      title: "Rainbow Style",
      id: "June 18, 2022 1:2:1",
      type: "Music",
      hash: 9,
      price: 0.003,
    },
    {
      image: item8,
      title: "Running Puppets",
      id: "",
      type: "Gallery",
      hash: 10,
      price: 0.005,
    },
    {
      image: item9,
      title: "Loop Donut",
      id: "July 01, 2022 1:6:6",
      type: "Video",
      hash: 11,
      price: 0.005,
    },
  ];

  async function convertBTC() {
    axios
      .get(
        "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=BTC,USD,EUR,MATIC,GoerliETH"
      )
      .then(function (response) {
        // handle success
        console.log(response);
        let ratec =
          parseFloat(response.data.USD) / parseFloat(response.data.MATIC);

        setRate(ratec);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function getdata(itemsrc, AuctionData) {
    AuctionData.map((item) => {
      console.log("item.image", item.image);
      console.log("image", itemsrc);
      if (item.image === itemsrc) {
        console.log("trueimg", item.title);

        return item.title;
      }
    });
  }

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location?.pathname === "/index-two-dark-rtl") {
        document.getElementById("theme-opt").href =
          "./css/style-dark-rtl.min.css";
      } else if (location?.pathname === "/index-two") {
        document.getElementById("theme-opt").href = "./css/style.min.css";
      } else if (location?.pathname === "/index-two-rtl") {
        document.getElementById("theme-opt").href = "./css/style-rtl.min.css";
      } else {
        document.getElementById("theme-opt").href = "./css/style-dark.min.css";
      }
    }, 100);
  }, [location?.pathname]);



  useEffect(() => {
    convertBTC();
  }, []);

  //nft related data

  //nft related data
  const [nftsall, setNftsall] = useState([]);
  const [loadedall, setLoadedall] = useState("not-loaded");
  const [price, setPrice] = useState('');


  const resell = async (tokenURI, price, id) => {
  const sell=  await createSale(tokenURI, price, true, id);
  localStorage.setItem('resell'+id, id);

  
  };







  async function loadNFTsall() {
    const url =
      "https://eth-goerli.alchemyapi.io/v2/9ctKkVZf-pCbOXxV3hT-2OhSm97FAmJg";
    const provider = new ethers.providers.JsonRpcProvider(url);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);
    const marketContract = new ethers.Contract(
      nftmarketaddress,
      Market.abi,
      provider
    );
    const data = await marketContract.fetchMarketItems();

    const items = await Promise.all(
      data.map(async (i) => {
        const tokenUri = await tokenContract.tokenURI(i.tokenId);
        const meta = await axios.get(tokenUri);
        let price = web3.utils.fromWei(i.price.toString(), "ether");
        let item = {
          price,
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: i.owner,
          image: meta.data.image,
          title: meta.data.name,
          meta: meta,
        };
        return item;
      })
    );
    console.log("items: ", items);
    setNftsall(items);
    setLoadedall("loaded");
  }

  // async function buyNft(nft) {
  //   const web3Modal = new Web3Modal({
  //     // network: "mainnet",
  //     cacheProvider: true,
  //   });
  //   const connection = await web3Modal.connect();
  //   const provider = new ethers.providers.Web3Provider(connection);
  //   const signer = provider.getSigner();
  //   const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer);

  //   const price = web3.utils.toWei(nft.price.toString(), "ether");

  //   console.log("price: ", price);

  //   const transaction = await contract.createMarketSale(
  //     nftaddress,
  //     nft.tokenId,
  //     {
  //       value: price,
  //     }
  //   );
  //   await transaction.wait();
  //   loadNFTsall();
  // }

  //end



  useEffect(() => {

    fetchNFTs()
    .then((items) => {
      setNftsall(items.reverse());
//SetLoading(false);
      // setNftsCopy(items);
     // setIsLoading(false);
    });

  
   // loadNFTsall();
    const queryString = window.location.search;
    var ret = queryString.replace("?", "");
  var nftDetail= JSON.parse(base64_decode(ret));
    setNft(nftDetail);
    console.log('nft',nft);

    
  }, []);

  //end

  function buildDataUrl(address: string) {
    return 'data:image/svg+xml;base64,' + btoa(Jazzicon(address));
  }

  return (
    <>
      {/* Navbar */}
      <Navbar />

      {/* Start */}
      <section className="bg-item-detail d-table w-100">
        <div className="container">
          <div className="row">
        
            
                <>
                  <div className="col-md-6">
                    <div className="sticky-bar">
                      <img
                        src={nft.image}
                        className="img-fluid rounded-md shadow"
                        alt=""
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                    <div className="ms-lg-5">
                      <div className="title-heading">
                        <h4 className="h3 fw-bold mb-0">
                          <span className="text-gradient-primary">
                            {nft.name}
                          </span>{" "}
                          <br />
                        </h4>
                      </div>

                      <div className="row">
                        <div className="col-md-6 mt-4 pt-2">
                          <h6>Current Price</h6>
                          <h4 className="mb-0">{nft.price} ETH</h4>
                          <small className="mb-0 text-muted">
                            ${( parseFloat(nft.price) *  parseFloat(1157.54) ).toFixed(2)}USD
                          </small>
                        </div>
                        <div className="col-md-6 mt-4 pt-2">
                          <h6>Resell Price</h6>
                          <div className="row">
                            <div className="col-md-12 mb-2">
                              <input type="text" className="input-txt-2" inputType="number"
                                    title="Price"
                                    placeholder="Enter Resell Price"
                                    onChange={(e) => setPrice(e.target.value)}
                                    ></input>
                              </div>    
                            </div>     
                          <small className="mb-0 text-muted">
                           { price=='' ?'0.00':( parseFloat(price ) *  parseFloat(1157.54) ).toFixed(2)} USD
                          </small>
                        </div>


                        <div className="col-12 mt-4 pt-2">
                            <a
                              href="#"
                              className="btn btn-l btn-pills btn-primary"
                              onClick={()=>{resell(nft.tokenURI, price, nft.tokenId)}}
                            >
                              <i className="mdi mdi-cart fs-5 me-2"></i> Resell
                            </a>
                        </div>

                        {isLoadingNFT?<div className="text-center"><div class="lds-facebook"><div></div><div></div><div></div></div><p className="text-gradient-info animate-charcter">PLEASE WAIT WHILE YOUR TRANSACTION IS PROCESSING!</p></div>:''}



                      </div>

                  <div className="row mt-4 pt-2">
                   

                    <div className="col-12">
                      <ul
                        className="nav nav-tabs border-bottom"
                        id="myTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="detail-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#detailItem"
                            type="button"
                            role="tab"
                            aria-controls="detailItem"
                            aria-selected="true"
                          >
                            Details
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content mt-4 pt-2" id="myTabContent">
                        <div>
                          <p className="text-muted">
                            {nft.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                    </div>
                  </div>

                 
                  
                </>
             
          
          </div>
        </div>

        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-4">Related Items</h4>
                <p className="text-muted para-desc mb-0 mx-auto">
                  We are a huge marketplace dedicated to connecting great
                  artists of all Superex with their fans and unique token
                  collectors!
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          <div className="row row-cols-xl-4 row-cols-lg-3 row-cols-sm-2 row-cols-1">
            {nftsall?.map((data) => {
              return (
                <div className="col mt-4 pt-2" key={data?.name}>
                  <div className="card nft-items nft-default nft-auction rounded-md shadow overflow-hidden mb-1 p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center">
                        <img
                          src={buildDataUrl(data.seller)}
                          alt="user"
                          className="avatar avatar-sm-sm img-thumbnail border-0 shadow-sm rounded-circle"
                        />
                        <a
                          href=""
                          onClick={(e) => e.preventDefault()}
                          className="text-dark small creator-name mb-0 ms-2 text-limit-1" 
                        >
                          @{data.seller}
                        </a>
                      </div>
                    </div>

                    <div className="nft-image rounded-md mt-3 position-relative overflow-hidden">
                      <a
                        herf="#"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            `/item-detail-one?${base64_encode(data)}`
                          );
                        }}
                      >
                        <img src={data?.image} className="img-fluid" alt="" />
                      </a>
                      
                      <div className="position-absolute top-0 end-0 m-2">
                        <span className="like-icon shadow-sm">
                          <a
                            href=""
                            onClick={(e) => e.preventDefault()}
                            className="text-muted icon"
                          >
                            <i className="mdi mdi-18px mdi-heart mb-0"></i>
                          </a>
                        </span>
                      </div>

                    
                    </div>

                    <div className="card-body content position-relative p-0 mt-3">
                      <a
                        href={`/item-detail-one?${base64_encode(data?.image)}`}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(
                            `/item-detail-one?${base64_encode(data)}`
                          );
                        }}
                        className="title text-dark h6"
                      >
                        {data?.name}
                      </a>
                      <div className="d-flex justify-content-between mt-2">
                            <div className="item-left-content">
                              <small className="rate fw-bold">
                                {data.price} {nftCurrency}
                              </small>
                            </div>
                            <div className="item-right-content">
                              <a
                                className="btn btn-icon btn-pills btn-outline-light-white me-2"
                                href={`https://mumbai.polygonscan.com/address/0x56047D1B5fd577e434a94B989b6CAB1416e94E2E`}
                                title="View On PolygonScan"
                                alt="View On PolygonScan"
                              >
                                <img
                                  style={{ width: "10px" }}
                                  src={ico08}
                                />
                              </a>                         
                            </div>
                          </div>
                    
                    </div>
                  </div>
                </div>
              );
            })}
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End */}

      {/* Place Bid Modal */}
      <div
        className="modal fade"
        id="NftBid"
        aria-hidden="true"
        aria-labelledby="bidtitle"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="modal-header">
              <h5 className="modal-title" id="bidtitle">
                Place a Bid
              </h5>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            <div className="modal-body p-4">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label fw-bold">
                        Your Bid Price <span className="text-danger">*</span>
                      </label>
                      <input
                        name="name"
                        id="name"
                        type="text"
                        className="form-control"
                        placeholder="00.00 ETH"
                      />
                      <small className="text-muted">
                        <span className="text-dark">Note:</span> Bid price at
                        least 1 ETH
                      </small>
                    </div>
                  </div>
                  {/*end col*/}
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label fw-bold">
                        Enter Your QTY <span className="text-danger">*</span>
                      </label>
                      <input
                        name="email"
                        id="email"
                        type="email"
                        className="form-control"
                        placeholder="0"
                      />
                      <small className="text-muted">
                        <span className="text-dark">Note:</span> Max. Qty 5
                      </small>
                    </div>
                  </div>
                  {/*end col*/}
                </div>
              </form>

              <div className="pt-3 border-top">
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> You must bid at least:</p>
                  <p className="text-primary"> 1.22 ETH </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> Service free:</p>
                  <p className="text-primary"> 0.05 ETH </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> Total bid amount:</p>
                  <p className="text-primary mb-0"> 1.27 ETH </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-pills btn-primary"
                data-bs-target="#placebid"
                data-bs-toggle="modal"
              >
                <i className="mdi mdi-gavel fs-5 me-2"></i> Place a Bid
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="placebid"
        aria-hidden="true"
        aria-labelledby="bidsuccess"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="modal-header">
              <h5 className="modal-title" id="bidsuccess">
                Bidding Successful
              </h5>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            <div className="modal-body p-4">
              your bid (1.27ETH) has been listing to our database
            </div>
            <div className="modal-footer">
              <a
                href="/activity"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/activity");
                }}
                data-bs-toggle="modal"
                className="btn btn-pills btn-primary"
              >
                <i className="mdi mdi-basket-plus fs-5 me-2"></i> View Your Bid
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Place Bid Modal */}

      {/* Buy Now NFt Modal */}
      <div
        className="modal fade"
        id="NftBuynow"
        aria-hidden="true"
        aria-labelledby="buyNft"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="modal-header">
              <h5 className="modal-title" id="buyNft">
                Checkout
              </h5>
              <button
                type="button"
                className="btn btn-close"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            <div className="modal-body p-4">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-4">
                      <label className="form-label fw-bold">
                        Your Price <span className="text-danger">*</span>
                      </label>
                      <input
                        name="name"
                        id="name"
                        type="text"
                        className="form-control"
                        defaultValue="1.5ETH"
                      />
                    </div>
                  </div>
                  {/*end col*/}
                </div>
              </form>

              <div className="py-3 border-top">
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> You must bid at least:</p>
                  <p className="text-primary"> 1.22 ETH </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> Service free:</p>
                  <p className="text-primary"> 0.05 ETH </p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold small"> Total bid amount:</p>
                  <p className="text-primary mb-0"> 1.27 ETH </p>
                </div>
              </div>

              <div className="bg-soft-danger p-3 rounded shadow">
                <div className="d-flex align-items-center">
                  <i className="uil uil-exclamation-circle h2 mb-0 me-2"></i>
                  <div className="flex-1">
                    <h6 className="mb-0">This creator is not verified</h6>
                    <small className="mb-0">
                      Purchase this item at your own risk
                    </small>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <button
                  className="btn btn-pills btn-primary w-100"
                  data-bs-target="#buyNftSuccess"
                  data-bs-toggle="modal"
                >
                  <i className="mdi mdi-cart fs-5 me-2"></i> Continue
                </button>
                <form>
                  <div className="form-check align-items-center d-flex mt-2">
                    <input
                      className="form-check-input mt-0"
                      type="checkbox"
                      id="AcceptT&C"
                    />
                    <label
                      className="form-check-label text-muted ms-2"
                      htmlFor="AcceptT&C"
                    >
                      I Accept{" "}
                      <a
                        href=""
                        onClick={(e) => e.preventDefault()}
                        className="text-primary"
                      >
                        Terms And Condition
                      </a>
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="buyNftSuccess"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered modal-sm">
          <div className="modal-content border-0 shadow-md rounded-md">
            <div className="position-absolute top-0 start-100 translate-middle z-index-1">
              <button
                type="button"
                className="btn btn-icon btn-pills btn-sm btn-light btn-close opacity-10"
                data-bs-dismiss="modal"
                id="close-modal"
              >
                <i className="uil uil-times fs-4"></i>
              </button>
            </div>
            <div className="modal-body text-center p-4">
              <h3>Yahhhoooo! 🎉</h3>
              <h6 className="text-muted mb-0">
                You successfully purchased{" "}
                <a href="" className="text-reset">
                  <u>XYZ nft</u>
                </a>{" "}
                from Superex
              </h6>

              <ul className="rounded-md shadow p-4 border list-unstyled mt-4">
                <li className="d-flex justify-content-between">
                  <span className="fw-bold me-5">Status:</span>
                  <span className="text-warning">Processing</span>
                </li>

                <li className="d-flex justify-content-between mt-2">
                  <span className="fw-bold me-5">Transaction ID:</span>
                  <span className="text-muted">qhut0...hfteh45</span>
                </li>
              </ul>

              <ul className="list-unstyled social-icon mb-0 mt-4">
                {[
                  "uil uil-facebook-f",
                  "uil uil-instagram",
                  "uil uil-linkedin",
                  "uil uil-dribbble",
                  "uil uil-twitter",
                ]?.map((data, index) => {
                  return (
                    <li className="list-inline-item lh-1 mr-1" key={index}>
                      <a href="" className="rounded">
                        <i className={data}></i>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Buy Now NFt Modal */}
      {/* footer */}
      <Footer />

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  );
};

export default ItemDetailTwo;
