import React, { useState, useEffect,useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { tns } from 'tiny-slider/src/tiny-slider';
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import StyleSwitcher from '../../components/StyleSwitcher'
import { players, player1, pi2 } from '../../components/imageImport'
import {NFTContext} from "../../context/NFTContext";
import { getCreators } from '../../util/getTopCreators';
import { shortenAddress } from '../../util/shortenAddress';

import { ethers } from 'ethers'


import web3 from 'web3'
import axios from 'axios'
import Web3Modal from "web3modal"
import Market from '../../artifacts/contracts/NFTMarket.sol/NFTMarket.json'
import NFT from '../../artifacts/contracts/NFT.sol/NFT.json'
import {
  nftmarketaddress, nftaddress
} from '../../config'
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import Jazzicon from '@raugfer/jazzicon';






import {
  work1, work2, work3, work4, work5, work6, work7, work8, work9, work10, work11, work12,
  client01, client02, client03, client04, client05, client06, client07, client08,
  client09, client10, client11, client12, client13,
  gif1, gif2, gif3, gif4, gif5, gif6, gif11, item1, item2, item3, item4, item5, item6,
  c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12,c13,c14,c15,c16, c17, c18,c19,c20,c21,c22,c23,
  popular1,popular2,popular3,popular4,popular5,popular6,
} from '../../components/imageImport'

const DarkVersionTwo = () => {
  const navigate = useNavigate()
  const [loading, SetLoading] = useState(true);
  useEffect(() => {
    SetLoading(false);
    if (document.getElementsByClassName('tiny-three-item-nav-arrow').length > 0) {
      var slider = tns({
        container: '.tiny-three-item-nav-arrow',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: 'bottom',
        controlsText: [
          '<i class="mdi mdi-chevron-left "></i>',
          '<i class="mdi mdi-chevron-right"></i>',
        ],
        nav: false,
        speed: 400,
        gutter: 12,
        responsive: {
          992: {
            items: 3,
          },

          767: {
            items: 2,
          },

          320: {
            items: 1,
          },
        },
      })
    }
    if (document.getElementsByClassName('tiny-five-item').length > 0) {
      var slider = tns({
        container: '.tiny-five-item',
        controls: false,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: 'bottom',
        speed: 400,
        gutter: 12,
        responsive: {
          1025: {
            items: 5,
          },

          992: {
            items: 3,
          },

          767: {
            items: 2,
          },

          320: {
            items: 1,
          },
        },
      })
    }
    if (document.getElementsByClassName('tiny-five-item-nav-arrow').length > 0) {
      var slider6 = tns({
        container: '.tiny-five-item-nav-arrow',
        controls: true,
        mouseDrag: true,
        loop: true,
        rewind: true,
        autoplay: true,
        autoplayButtonOutput: false,
        autoplayTimeout: 3000,
        navPosition: 'bottom',
        controlsText: [
          '<i class="mdi mdi-chevron-left "></i>',
          '<i class="mdi mdi-chevron-right"></i>',
        ],
        nav: false,
        speed: 400,
        gutter: 10,
        responsive: {
          992: {
            items: 5,
          },

          767: {
            items: 3,
          },

          320: {
            items: 1,
          },
        },
      })
    }
  }, [])

  const toggleSwitcher = () => {
    var i = document.getElementById('style-switcher')
    if (i) {
      if (i.style.left === '-189px') {
        i.style.left = '0px'
      } else {
        i.style.left = '-189px'
      }
    }
  }

  const creator = [
    {
      background: work1,
      Image: client01,
      name: 'Steven Townsend',
      author: 'StreetBoy',
    },
    {
      background: work2,
      Image: client02,
      name: 'Tiffany Betancourt',
      author: 'CutieGirl',
    },
    {
      background: work3,
      Image: client03,
      name: 'Mari Harrington',
      author: 'NorseQueen',
    },
    {
      background: work4,
      Image: client04,
      name: 'Floyd Glasgow',
      author: 'BigBull',
    },
    {
      background: work5,
      Image: client05,
      name: 'Donna Schultz',
      author: 'Angel',
    },
    {
      background: work6,
      Image: client06,
      name: 'Joshua Morris',
      author: 'CrazyAnyone',
    },
    {
      background: work7,
      Image: client07,
      name: 'Carl Williams',
      author: 'LooserBad',
    },
    {
      background: work8,
      Image: client08,
      name: 'Eugene Green',
      author: 'KristyHoney',
    },
    {
      background: work9,
      Image: client09,
      name: 'Julius Canale',
      author: 'PandaOne',
    },
    {
      background: work10,
      Image: client10,
      name: 'Michael Williams',
      author: 'FunnyGuy',
    },
    {
      background: work11,
      Image: client11,
      name: 'Jacqueline Burns',
      author: 'ButterFly',
    },
    {
      background: work12,
      Image: client12,
      name: 'Rosaria Vargas',
      author: 'Princess',
    },
  ]

  const AuctionData = [
    {
      image: gif1,
      title: 'Deep Sea Phantasy',
      type: 'GIFs',
      filter: ['all', 'games'],
    },
    {
      image: item1,
      title: 'CyberPrimal 042 LAN',
      type: 'Arts',
      filter: ['all', 'art'],
    },
    {
      image: gif2,
      title: 'Crypto Egg Stamp #5',
      type: 'Games',
      filter: ['all', 'music', 'meme'],
    },
    {
      image: item2,
      title: 'Colorful Abstract Painting',
      type: '',
      filter: ['all', 'video'],
    },
    {
      image: item3,
      title: 'Liquid Forest Princess',
      type: '',
      filter: ['all', 'video', 'meme'],
    },
    {
      image: gif3,
      title: 'Spider Eyes Modern Art',
      type: 'GIFs',
      filter: ['all', 'games'],
    },
    {
      image: item4,
      title: 'Synthwave Painting',
      type: '',
      filter: ['all', 'art'],
    },
    {
      image: gif4,
      title: 'Contemporary Abstract',
      type: 'GIFs',
      filter: ['all', 'music'],
    },
    {
      image: item5,
      title: 'Valkyrie Abstract Art',
      type: '',
      filter: ['all', 'video', 'meme'],
    },
    {
      image: gif5,
      title: 'The girl with the firefly',
      type: '',
      filter: ['all', 'art'],
    },
    {
      image: item6,
      title: 'Dodo hide the seek',
      type: '',
      filter: ['all', 'games'],
    },
    {
      image: gif6,
      title: 'Pinky Ocean',
      type: '',
      filter: ['all', 'music'],
    },
  ]

  const bestCreator = [
    {
      profileIcon: true,
      image: client01,
      name: 'StreetBoy',
      eth: '0.5 ETH',
    },
    {
      profileIcon: false,
      image: client13,
      name: 'FunnyGuy',
      eth: '0.25 ETH',
    },
    {
      profileIcon: true,
      image: client02,
      name: 'CutieGirl',
      eth: '0.2 ETH',
    },
    {
      profileIcon: true,
      image: client09,
      name: 'PandaOne',
      eth: '1.5 ETH',
    },
    {
      profileIcon: false,
      image: client03,
      name: 'NorseQueen',
      eth: '2.5 ETH',
    },
    {
      profileIcon: false,
      image: client04,
      name: 'BigBull',
      eth: '3.5 ETH',
    },
    {
      profileIcon: true,
      image: client10,
      name: 'KristyHoney',
      eth: '0.8 ETH',
    },
    {
      profileIcon: false,
      image: client05,
      name: 'Angel',
      eth: '0.55 ETH',
    },
    {
      profileIcon: true,
      image: client11,
      name: 'ButterFly',
      eth: '0.5 ETH',
    },
    {
      profileIcon: true,
      image: client06,
      name: 'CrazyAnyone',
      eth: '0.1 ETH',
    },
    {
      profileIcon: false,
      image: client07,
      name: 'LooserBad',
      eth: '0.25 ETH',
    },
    {
      profileIcon: true,
      image: client12,
      name: 'Princess',
      eth: '0.5 ETH',
    },
  ]

  const collectionData = [
    {
      title: 'Lamborghini ',
      img1: popular1,
      img2: c1,
      img3: c4,
      img4: c10,
      client: client01,
      item_count:'05',
    },
    {
      title: 'Ferrari',
      img1: popular2,
      img2: c14,
      img3: c15,
      img4: c11,
      client: client10,
      item_count:'03',
    },
    {
      title: 'Aston Martin',
      img1: popular3,
      img2: c9,
      img3: c12,
      img4: c13,
      client: client01,
      item_count:'05',
    },
    {
      title: 'Tesla',
      img1: popular4,
      img2: c16,
      img3: c17,
      img4: c18,
      client: client01,
      item_count:'05',
    },
    {
      title: 'Porsche',
      img1: popular5,
      img2: c19,
      img3: c20,
      img4: c2,
      client: client10,
      item_count:'03',
    },
    {
      title: 'Mercedes-Benz',
      img1: popular6,
      img2: c21,
      img3: c22,
      img4: c23,
      client: client10,
      item_count:'03',
    },
  ]
  const [allData, setAllData] = useState(AuctionData)
  const [type, setType] = useState('all')
  const location = useLocation()

  useEffect(() => {
    setTimeout(() => {
      if (location?.pathname === '/index-four-dark-rtl') {
        document.getElementById('theme-opt').href = './css/style-dark-rtl.min.css'
      } else if (location?.pathname === '/token-sports') {
        document.getElementById('theme-opt').href = './css/style.min.css'
      } else if (location?.pathname === '/index-four-rtl') {
        document.getElementById('theme-opt').href = './css/style-rtl.min.css'
      } else {
        document.getElementById('theme-opt').href = './css/style-dark.min.css'
      }
      toggleSwitcher(false)
    }, 100)
  }, [location?.pathname])

  const setFilter = type => {
    setType(type)
    const newOne = AuctionData?.filter(data => data?.filter?.includes(type))
    setAllData(newOne)
  }






  const [ele, setEle] = useState('')
  //const [nfts, setNfts] = useState([])
  const [loaded, setLoaded] = useState('not-loaded')
  async function loadNFTs() {
    const web3Modal = new Web3Modal({
      network: "https://eth-goerli.alchemyapi.io/v2/9ctKkVZf-pCbOXxV3hT-2OhSm97FAmJg",
      cacheProvider: true,
    });
    const connection = await web3Modal.connect()
    const provider = new ethers.providers.Web3Provider(connection)
    const signer = provider.getSigner()
      
    const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
    const data = await marketContract.fetchMyNFTs()
    
    const items = await Promise.all(data.map(async i => {
      const tokenUri = await tokenContract.tokenURI(i.tokenId)
      const meta = await axios.get(tokenUri)
      let price = web3.utils.fromWei(i.price.toString(), 'ether');
      let item = {
        price,
        tokenId: i.tokenId.toNumber(),
        seller: i.seller,
        owner: i.owner,
        image: meta.data.image,
      }
      return item
    }))
    console.log('items: ', items)
    setNfts(items)
    setLoaded('loaded') 
  }



//=========================================all nfts

const [nftsall, setNftsall] = useState([])
const [loadedall, setLoadedall] = useState('not-loaded')
const [currentAccount, setCurrentAccount] = useState(null);
const [nftbyseller, setNftbyseller] = useState([]);
const [collection, setCollection] = useState([]);

const { fetchNFTs } = useContext(NFTContext);
  const [nfts, setNfts] = useState([]);



const checkWalletIsConnected = async () => {
  const { ethereum } = window;

  if (!ethereum) {
    console.log("Make sure you have Metamask installed!");
    return;
  } else {
    console.log("Wallet exists! We're ready to go!")
  }

  const accounts = await ethereum.request({ method: 'eth_accounts' });

  if (accounts.length !== 0) {
    const account = accounts[0];
    console.log("Found an authorized account: ", account);
    setCurrentAccount(account);
  } else {
    console.log("No authorized account found");
  }
}

const connectWalletHandler = async () => {
  const { ethereum } = window;

  if (!ethereum) {
    alert("Please install Metamask!");
  }

  try {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    console.log("Found an account! Address: ", accounts[0]);
    setCurrentAccount(accounts[0]);
    setEle(accounts[0]);
    const imageUrl = buildDataUrl(accounts[0]);
    
    setEle(imageUrl);
  } catch (err) {
    console.log(err)
  }
}




useEffect(() => {
loadNFTsall()

fetchNFTs()
      .then((items) => {
        setNfts(items.reverse());
       
      });

  
  checkWalletIsConnected();
}, [])



async function loadNFTsall() {
  const url = "https://eth-goerli.alchemyapi.io/v2/9ctKkVZf-pCbOXxV3hT-2OhSm97FAmJg";
  const provider = new ethers.providers.JsonRpcProvider(url)
  const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
  const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, provider)
  const data = await marketContract.fetchMarketItems()
  

  


  const items = await Promise.all(data.map(async i => {
    const tokenUri = await tokenContract.tokenURI(i.tokenId)
    const meta = await axios.get(tokenUri)
    let price = web3.utils.fromWei(i.price.toString(), 'ether');
    let item = {
      price,
      tokenId: i.tokenId.toNumber(),
      seller: i.seller,
      owner: i.owner,
      image: meta.data.image,
      collection: meta.data.hasOwnProperty("attributes")?meta.data.attributes[0]?.collection:'Genaral'
      
       
    }
    return item
  }))
  console.log('items: ', items)
  setNftsall(items)

//by seller

const result = Object.values(items.reduce((r, e) => {
  let k = `${e.seller}`;
  if(!r[k]) r[k] = {...e, count: 1}
    else r[k].count += 1;
  return r;
}, {}))




const result2 = Object.values(items.reduce((r, e) => {
  let k = `${e.collection}`;
  let b= [];
  if(!r[k]){    b= []
   
    r[k] = {...e, count: 1,'thumbnail':[e.image] }
  }
      else{
      r[k].thumbnail.push(e.image)
      r[k].count += 1;
    } 
  return r;
}, {}))


console.log('result: ', result);

setNftbyseller(result);
setCollection(result2)
console.log('collection: ', result2);
//end by seller

  setLoadedall('loaded') 
}
async function buyNft(nft) {
  const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
  });
  const connection = await web3Modal.connect()
  const provider = new ethers.providers.Web3Provider(connection)
  const signer = provider.getSigner()
  const contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
  
  const price = web3.utils.toWei(nft.price.toString(), 'ether');

  console.log('price: ', price);
  
  const transaction = await contract.createMarketSale(nftaddress, nft.tokenId, {
    value: price
  })
  await transaction.wait()
  loadNFTsall()
}



function buildDataUrl(address: string): string {
  return 'data:image/svg+xml;base64,' + btoa(Jazzicon(address));
}


const creators = getCreators(nfts);











  return (
    <>
      {/* Navbar */}
      <div className="round-effect">
        <div className="primary-round opacity-3"></div>
        <div className="gradient-round opacity-3"></div>
      </div>

      <Navbar />

      {/* Start Home */}
      <section className="bg-half-100">
        <div className="container">
        <div className="row align-items-center">
            
            {/*start slide*/}
            
            <div className="col">
              <div className="tiny-one-item-nav-arrow">
                    <div className="card bg-white collections collection-primary rounded-md shadow p-2 pb-0 m-1">
                      <div className="row g-2">
                        <div className="col-12">
                          <img
                            src={gif11}
                            className="img-fluid shadow-sm rounded-md"
                            alt=""
                          />
                        </div>             
                                   
                      </div>                                      
                    </div> 
              </div>
            </div>         
         
            {/*end slide*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}

        
        <div className="container mt-100 mt-60">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title text-center mb-4 pb-2">
                <h4 className="title mb-2">Popular Number Plates</h4>
                <p className="text-muted mb-0">
                  Best Collection of the week's NFTs
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}


          <div className="row">
            <div className="col">
              <div className="tiny-three-item-nav-arrow">
                {collectionData?.map(data => {
                  return (<div className="tiny-slide" key={data?.title}>
                    <div className="card bg-white collections collection-primary rounded-md shadow p-2 pb-0 m-1">
                      <div className="row g-2">
                        <div className="col-12">
                          <img
                            src={data?.img1}
                            className="img-fluid shadow-sm rounded-md"
                            alt=""
                          />
                        </div>
                        {/*end col*/}

                        <div className="col-4">
                          <img
                            src={data?.img2}
                            className="img-fluid shadow-sm rounded-md"
                            alt=""
                          />
                        </div>
                        {/*end col*/}

                        <div className="col-4">
                          <img
                            src={data?.img3}
                            className="img-fluid shadow-sm rounded-md"
                            alt=""
                          />
                        </div>
                        {/*end col*/}

                        <div className="col-4">
                          <img
                            src={data?.img4}
                            className="img-fluid shadow-sm rounded-md"
                            alt=""
                          />
                        </div>
                        {/*end col*/}
                      </div>
                      {/*end row*/}

                      <div className="content text-center p-4 mt-n5">
                        <div className="position-relative d-inline-flex">
                         
                          <span className="verified text-primary pt-4">
                            <i className="mdi mdi-check-decagram"></i>
                          </span>
                        </div>

                        <div className="mt-2">
                          <a
                            href="/"
                            onClick={e => {
                              e.preventDefault()
                              navigate('/explore-two?'+data?.title)
                            }}
                            className="text-dark title h5"
                          >
                            {data?.title}
                          </a>

                          {/*<p className="text-muted mb-0 small">10 Items</p>*/}
                        </div>
                      </div>
                    </div>
                  </div>)
                })}
              </div>

              {/*end slide*/}
            </div>
          </div>
          {/*end row*/}


         

      
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      {/* End Home */}

      {/* footer */}
      <Footer />

      {/* Style switcher  */}
     
    </>
  )
}

export default DarkVersionTwo
