// import React,{ useState, useEffect } from 'react'
import  React,{ useState, useEffect,useMemo, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer'
import Navbar from '../../components/Navbar'
import StyleSwitcher from '../../components/StyleSwitcher'
import { work1, client01, bg01 } from '../../components/imageImport'
import { ethers } from 'ethers'
import { create as ipfsHttpClient } from 'ipfs-http-client'
import { NFTContext } from '../../context/NFTContext';
import Web3Modal from 'web3modal'

import web3 from 'web3'
import {
  nftaddress, nftmarketaddress
} from '../../config'
import Jazzicon from '@raugfer/jazzicon';
import NFT from '../../artifacts/contracts/NFT.sol/NFT.json'
import Market from '../../artifacts/contracts/NFTMarket.sol/NFTMarket.json'
import Swal from 'sweetalert2'
import S3 from 'react-aws-s3';






var jazzicon = require('jazzicon')
const axios = require('axios')
const REACT_APP_PINATA_API_KEY='fa2774fd0465db7f7efc'
const REACT_APP_PINATA_API_SECRET='317eeb945ba4b4f076ecafda672b8ca1a5d78efcd7f2fa8e5833a76706c3601c'


//const client = ipfsHttpClient('https://ipfs.infura.io:5001/api/v0')

const S3_BUCKET ='artsandcrafts-dev';
//const REGION ='YOUR_DESIRED_REGION_HERE';





// installed using npm install buffer --save
window.Buffer = window.Buffer || require("buffer").Buffer;

// the configuration information is fetched from the .env file
const config = {
  bucketName: S3_BUCKET,
  region: 'ap-southeast-1',
  accessKeyId: 'AKIAVKA6OPBVXIRAAIRB',
  secretAccessKey: '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',
}


const UploadWork = () => {
  const navigate = useNavigate()
  const [loading, SetLoading] = useState(false);
  
  const handleChange = () => {
    const fileUploader = document.querySelector('#input-file')
    const getFile = fileUploader.files
    if (getFile.length !== 0) {
      const uploadedFile = getFile[0]
      readFile(uploadedFile)




    }
  }


  function buildDataUrl(address) {
    return 'data:image/svg+xml;base64,' + btoa(Jazzicon(address));
  }
 
  useEffect(() => {
    if (localStorage.getItem("token") === null) {
      connectWalletHandler()
     // useNavigate('/login')
      navigate('/login');
    }
   
  },[])

//=========================================nft here


const [file, setFile] = useState(null)
const [fileUrl, setFileUrl] = useState(null)
const [formInput, updateFormInput] = useState({ price: '', name: '', description: '',category:'' })
const { createSale, isLoadingNFT } = useContext(NFTContext);
const [img, setImg] = useState(null)
const [sloc, setSloc] = useState(null);


/*
async function createSale(url) {
  const web3Modal = new Web3Modal({
    network: "mainnet",
    cacheProvider: true,
  });
  const connection = await web3Modal.connect()
  const provider = new ethers.providers.Web3Provider(connection)    
  const signer = provider.getSigner()
  
  let contract = new ethers.Contract(nftaddress, NFT.abi, signer)
  let transaction = await contract.createToken(url)
  let tx = await transaction.wait()
  let event = tx.events[0]
  let value = event.args[2]
  let tokenId = value.toNumber()
  const price = web3.utils.toWei(formInput.price, 'ether')

  const listingPrice = web3.utils.toWei(formInput.price, 'ether')

  contract = new ethers.Contract(nftmarketaddress, Market.abi, signer)
  transaction = await contract.createMarketItem(nftaddress, tokenId, price, { value: listingPrice })
  
  await transaction.wait()
  SetLoading(false);
 // router.push('/')
}

*/


function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
 charactersLength));
   }
   return result;
}






async function onChange(e) {
  const file = e.target.files[0];


//   AWS.config.update({
//     accessKeyId: 'AKIAVKA6OPBVXIRAAIRB',
//     secretAccessKey: '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'
// })

// const myBucket = new AWS.S3({
//     params: { Bucket: S3_BUCKET},
//     region: 'ap-southeast-1',
// });


//   const params = {
//     ACL: 'public-read',
//     Body: file,
//     Bucket: S3_BUCKET,
//     Key: file.name
// };

// await myBucket.putObject(params)
//     .on('httpUploadProgress', (evt) => {
//       console.log(evt);
//         //setProgress(Math.round((evt.loaded / evt.total) * 100))
//     })
//     .send((err) => {
//         if (err) console.log(err)
//     })
console.log('....');
console.log(file);
console.log('....');
let   nfile=   uuidv4()+'.'+file.name.split('.').pop();
 //file.split('.').pop() 
const ReactS3Client = new S3(config);
// the name of the file uploaded is used to upload it to S3
ReactS3Client
.uploadFile(file,nfile)
.then(data => setImg(data.location))
.catch(err => console.error(err))




  try {
    // const added = await client.add(
    //   file,
    //   {
    //     progress: (prog) => console.log(`received: ${prog}`)
    //   }
    // )

    const formData = new FormData();
    formData.append("file", file);
    setFile(file);
 const resFile = await axios({
                    method: "post",
                    url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
                    data: formData,
                    headers: {
                        'pinata_api_key': `${REACT_APP_PINATA_API_KEY}`,
                        'pinata_secret_api_key': `${REACT_APP_PINATA_API_SECRET}`,
                        "Content-Type": "multipart/form-data"
                    },
                });

                const ImgHash = `ipfs://${resFile.data.IpfsHash}`;
    const hash=resFile.data.IpfsHash;



//alert(ImgHash);




    const url = `https://gateway.pinata.cloud/ipfs/${hash}`
    setFileUrl(url)
  } catch (error) {
    alert('Error uploading file: ');
    console.log('Error uploading file: ', error);
  }  
}
  
  
  
async function createMarket(e) {
  SetLoading(true);
  e.preventDefault();
  const { name, description, price ,category} = formInput
  if(price === ''){
    alert("Please add rate");
    return;
  }
  
  
  if (!name || !description || !price || !fileUrl) return
  // first, upload to IPFS
if(category ==''){
  category='General';
}

console.log({category});
  //category
  const datajsn = JSON.stringify({
    name, description, image: fileUrl,
    attributes:[
      {
      'collection':  category
    }
    ]
  })
  try {
   // const added = await client.add(data)
   // const url = `https://gateway.pinata.cloud/ipfs/`



    var data = JSON.stringify({
      "pinataOptions": {
        "cidVersion": 1
      },
      "pinataMetadata": {
        "name": name,
        "description":description,
        "image": img,
        "keyvalues": {
          "collection":  category,
         
        }
      },
      "pinataContent": {
        "name":name,
        "description":description,
        "image": img,

        "fileUrl":fileUrl,
        "attributes":[
          {"collection":  category }
        ]
         

      }
    });
    

var sData=JSON.stringify({
  "name":name,
  "description":description,
  "image": img,

  "fileUrl":img,
  "attributes":[
    {"collection":  category }
  ]
});


    
    
    
    
    const configs3 = {
      bucketName: S3_BUCKET,
      region: 'ap-southeast-1',
      accessKeyId: 'AKIAVKA6OPBVXIRAAIRB',
      secretAccessKey: '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',
    }
  
  var buf = Buffer.from(sData);
    var filen=`test${makeid(6)}.json`;
    var locs='https://artsandcrafts-dev.s3-ap-southeast-1.amazonaws.com/'+filen;
  const ReactS3Client = new S3(configs3);
ReactS3Client
.uploadFile(buf,filen)
.then(data => setSloc(locs))
.catch(err => console.error(err))




    var config = {
      method: 'post',
      url: 'https://api.pinata.cloud/pinning/pinJSONToIPFS',
     headers: {
        'pinata_api_key': `${REACT_APP_PINATA_API_KEY}`,
        'pinata_secret_api_key': `${REACT_APP_PINATA_API_SECRET}`,
       'Content-Type': 'application/json', 
    },
      data : data
    };
    
    const res = await axios(config);
    
    console.log(res.data);

    const meta =res.data;



const metaurl='https://gateway.pinata.cloud/ipfs/'+meta.IpfsHash;



   
    createSale(locs, price).catch((error) => {
        console.error(error)
        alert(error.message);
      
    })

    Swal.fire(
      'Success',
      'Files Uploaded to IPFS',
      'success'
    )
    SetLoading(true);

    setTimeout(function() {
      //window.location.reload();
      navigate('/creator-profile')
      SetLoading(false);
    }, 50000);

  } catch (error) {
    console.log('Error uploading file: ', error);

    Swal.fire(
      'Error',
      'Error Files Uploaded to IPFS',
      'error'
    )
  }  
}

const [currentAccount, setCurrentAccount] = useState(null);
const [ele, setEle] = useState('0xC871D4F6cFAc76b2c8b3a4F9a821279DD80e4813')
const connectWalletHandler = async () => {
  const { ethereum } = window;

  if (!ethereum) {
    alert("Please install Metamask!");
  }

  try {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    console.log("Found an account! Address: ", accounts[0]);
    setCurrentAccount(accounts[0]);
    setEle(accounts[0]);
    const imageUrl = buildDataUrl(accounts[0]);
    
    setEle(imageUrl);
  } catch (err) {
    console.log(err)
  }
}



//end nft










  const readFile = uploadedFile => {
    if (uploadedFile) {
      const reader = new FileReader()
      reader.onload = () => {
        const parent = document.querySelector('.preview-box')
        parent.innerHTML = `<img className="preview-content" src=${reader.result} />`
      }

      reader.readAsDataURL(uploadedFile)
    }
  }
  return (
    <>
      {/* Navbar */}
      <Navbar />
      {/* Start Home */}
      <section
        className="bg-half-170 d-table w-100"
        style={{ background: `url(${bg01}) bottom` }}
      >
        <div className="bg-overlay bg-gradient-overlay-2"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold sub-heading text-white title-dark">
                  Upload Your Work
                </h5>
                <p className="text-white-50 para-desc mx-auto mb-0">
                  Add your digital art and work
                </p>
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}

          
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}
      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      {/* End Home */}

      {/* Start */}
      <section className="section pt-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 order-2 order-md-1 mt-4 pt-2 mt-sm-0 pt-sm-0">
              <div className="card creators creator-primary rounded-md shadow overflow-hidden sticky-bar">
                <div
                  className="py-5"
                  style={{ background: `url(${work1})` }}
                ></div>
                <div className="position-relative mt-n5">
                  <img
                    src={
                      buildDataUrl('0xC871D4F6cFAc76b2c8b3a4F9a821279DD80e4813')}
                    className="avatar avatar-md-md rounded-pill shadow-sm bg-light img-thumbnail mx-auto d-block"
                    alt=""
                  />

                  <div className="content text-center pt-2 p-4">
                    <h6 className="mb-0">{currentAccount}</h6>
                    {/* <small className="text-muted">@StreetBoy</small> */}

                    <ul className="list-unstyled mb-0 mt-3" id="navmenu-nav">
                      <li className="px-0">
                        <a
                          href="/creator-profile"
                          onClick={e => {
                            e.preventDefault()
                            navigate('/creator-profile')
                          }}
                          className="d-flex align-items-center text-dark"
                        >
                          <span className="fs-6 mb-0">
                            <i className="uil uil-user"></i>
                          </span>
                          <small className="d-block fw-semibold mb-0 ms-2">
                            Profile
                          </small>
                        </a>
                      </li>

                      <li className="px-0 mt-2">
                        <a
                          href="/creator-profile-edit"
                          onClick={e => {
                            e.preventDefault()
                            navigate('/creator-profile-edit')
                          }}
                          className="d-flex align-items-center text-dark"
                        >
                          <span className="fs-6 mb-0">
                            <i className="uil uil-setting"></i>
                          </span>
                          <small className="d-block fw-semibold mb-0 ms-2">
                            Settings
                          </small>
                        </a>
                      </li>

                      <li className="px-0 mt-2">
                        <a
                          href="/lock-screen"
                          onClick={e => {
                            e.preventDefault()
                            navigate('/login')
                          }}
                          className="d-flex align-items-center text-dark"
                        >
                          <span className="fs-6 mb-0">
                            <i className="uil uil-sign-in-alt"></i>
                          </span>
                          <small className="d-block fw-semibold mb-0 ms-2">
                            Logout
                          </small>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            {/*end col*/}

            <div className="col-lg-9 col-md-8 order-1 order-md-2">
              <div className="card rounded-md shadow p-4">
                <div className="row">
                  <div className="col-lg-5">
                    <div className="d-grid">
                      <p className="fw-semibold mb-4">
                        Upload your ART here, Please click "Upload Image"
                        Button.
                      </p>
                      <div className="preview-box d-block justify-content-center rounded-md shadow overflow-hidden bg-light text-muted p-2 text-center small">
                        Supports JPG, PNG and MP4 videos. Max file size : 10MB.
                      </div>
                      <input
                        type="file"
                        id="input-file"
                        name="input-file"
                        accept="image/*"
                        onChange={(e) => {handleChange(e);onChange(e)} }
                        
                        hidden
                      />
                      <label
                        className="btn-upload btn btn-primary rounded-md mt-4"
                        htmlFor="input-file"
                      >
                        Upload Image
                      </label>
                    </div>
                  </div>
                  {/*end col*/}

                  <div className="col-lg-7 mt-4 mt-lg-0">
                    <div className="ms-lg-4">
                      <form>
                        <div className="row">
                          <div className="col-12 mb-4">
                            <label className="form-label fw-bold">
                              Art Title <span className="text-danger">*</span>
                            </label>
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control"
                              placeholder="Title :"
                              onChange={e => updateFormInput({ ...formInput, name: e.target.value })}
                            />
                          </div>
                          {/*end col*/}

                          <div className="col-12 mb-4">
                            <label className="form-label fw-bold">
                              {' '}
                              Description :{' '}
                            </label>
                            <textarea
                              name="comments"
                              id="comments"
                              rows="4"
                              className="form-control"
                              placeholder="Description :"
                              onChange={e => updateFormInput({ ...formInput, description: e.target.value })}
                             
                            ></textarea>
                          </div>
                          {/*end col*/}

                          <div className="col-md-12 mb-4">
                            <label className="form-label fw-bold">Category :</label>

                               <select className="form-control"  onChange={e => updateFormInput({ ...formInput, category: e.target.value })}>

                            <option value="">Please Select</option>
                              <option value="Lamborghini">Lamborghini</option>
                              <option value="Ferrari">Ferrari</option>
                              <option value="Aston Martin">Aston Martin</option>
                              <option value="Tesla">Tesla</option>
                              <option value="Porsche">Porsche</option>
                              <option value="Mercedes-Benz">Mercedes-Benz</option>
                            </select>
                          </div>

                          <div className="col-md-6 mb-4">
                            <label className="form-label fw-bold">Type :</label>
                            <select className="form-control">
                              <option>GIFs</option>
                              <option>Music</option>
                              <option>Video</option>
                              <option>Tech</option>
                            </select>
                          </div>
                          {/*end col*/}

                          <div className="col-md-6 mb-4">
                            <label className="form-label fw-bold">
                              {' '}
                              Rate :{' '}
                            </label>
                            <input
                              name="time"
                              type="text"
                              className="form-control"
                              id="time"
                              defaultValue="0.01ETH"
                              onChange={e => updateFormInput({ ...formInput, price: e.target.value })}
                            />
                          </div>
                          {/*end col*/}

                          
                          <div className="col-lg-12">
                            <button
                              type="submit"
                              className="btn btn-primary rounded-md"
                              onClick={(e)=>{createMarket(e)}}
                            >
                              Create Item
                            </button>                           
                          </div>
                          {/*end col*/}
                        </div>
                      </form>
                    </div>
                  </div>
                  {/*end col*/}
                </div>

                <div className="row justify-content-center">
                  <div className="col-lg-12 mt-3">
                  {loading?<div className="text-center"><div class="lds-facebook"><div></div><div></div><div></div></div><p className="animate-charcter">Your request is being processed. Please check your wallet.</p></div>:''}
                  </div>  
                </div>
                {/*end row*/}
              </div>
            </div>
            {/*end col*/}
          </div>
          {/*end row*/}
        </div>
        {/*end container*/}
      </section>
      {/*end section*/}

      
      {/* End */}

      {/* footer */}
      <Footer />

      {/* Style switcher  */}
      {/* <StyleSwitcher /> */}
    </>
  )
}

export default UploadWork
